<script>
import Dropdown from "@/components/Dropdown.vue";


export default {
  components: {Dropdown,},
  name: "Player",
  props: ['track'],
  data() {
    return {
      play: false,
      audio: new Audio('https://www.otherapi.cloud/spotify/download?id=XcKdw4LCjlvCpsKEwpTCsXPCosKsXWLCkHtbw4fCn3nClXs=&quality=MP3_96&expire=1708333368000'),
      currentTime: '00:00',
      range: 0,
      playback: 1,
      show_modal:false,
      title: '',
      duration: '',
      artists: '',
      album: '',
      cover: '',
    }
  },
  created() {
    this.title = this.track['title']
    this.duration = this.track['duration_str']
    this.artists = this.track['artists'][0]
    this.album = this.track['album']
    this.cover = this.track['cover']
    this.audio = new Audio(this.track['download'][1]['url'])
  },
  methods: {
    Play(){
      this.play = !this.play
      if (this.play) {
        this.audio.play();
        this.audio.addEventListener('timeupdate', () => {
          function str_pad_left(string, pad, length) {
            return (new Array(length + 1).join(pad) + string).slice(-length);
          }
          const minutes = Math.floor(this.audio.currentTime / 60);
          const seconds = Math.floor(this.audio.currentTime % 60);
          this.currentTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
          this.range = this.audio.currentTime
        })
      }
      if (this.play==false) {
        this.audio.pause()
      }
    },
    forward(){
      this.audio.currentTime += 10
      function str_pad_left(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
      }
      const minutes = Math.floor(this.audio.currentTime / 60);
      const seconds = Math.floor(this.audio.currentTime % 60);
      this.currentTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
      this.range = this.audio.currentTime
    },
    backward(){
      this.audio.currentTime -= 10
      function str_pad_left(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
      }
      const minutes = Math.floor(this.audio.currentTime / 60);
      const seconds = Math.floor(this.audio.currentTime % 60);
      this.currentTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
      this.range = this.audio.currentTime
    },
    playbackRate(rate){
      this.audio.playbackRate = rate
      this.playback = rate
    },
    Modal(){
      this.show_modal = !this.show_modal
      this.play = false
      this.audio.pause()
    }
  },
}

</script>

<template>
  <!-- Modal toggle -->
<svg @click="Modal" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
          </svg>

<div v-if="show_modal" class="flex bg-gray-600 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-screen max-h-full">
  <div class="mt-6 sm:mt-10 relative z-10 rounded-xl shadow-xl">
    <div class="bg-white border-slate-100 transition-all duration-500 dark:bg-slate-800 transition-all duration-500 dark:border-slate-500 border-b rounded-t-xl p-4 pb-6 sm:p-10 sm:pb-8 lg:p-6 xl:p-10 xl:pb-8 space-y-6 sm:space-y-8 lg:space-y-6 xl:space-y-8">
      <div class="flex items-center space-x-4 rtl:gap-4">
        <img :src="cover" loading="lazy"
             decoding="async" alt="" class="flex-none rounded-lg bg-slate-100" width="88" height="88">
        <div class="min-w-0 flex-auto space-y-1 font-semibold">
          <p class="text-cyan-500 transition-all duration-500 dark:text-cyan-400 text-sm leading-6">
            {{ title }}
          </p>
          <h2 class="text-slate-500 transition-all duration-500 dark:text-slate-400 text-sm leading-6 truncate">
            {{ album }}
          </h2>
          <p class="text-slate-900 transition-all duration-500 dark:text-slate-50 text-lg">
            {{ artists }}
          </p>
        </div>
        <button @click="Modal" type="button"
                class="place-self-start text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
      </div>
      <div class="flex flex-col px-10 pb-6 z-50">
        <input type="range" id="song-percentage-played" class="amplitude-song-slider mb-3" step=".1" :value="range">
        <!--        times-->
        <div class="flex w-full justify-between">
          <span
              class="amplitude-current-time text-xs font-sans tracking-wide font-medium text-sky-500 dark:text-sky-300">{{
              currentTime
            }}</span>
          <span class="amplitude-duration-time text-xs font-sans tracking-wide font-medium text-gray-500">{{ duration }}</span>
        </div>
        <div class="space-y-2">
        </div>
      </div>
    </div>
    <div
        class="bg-slate-50 text-slate-500 transition-all duration-500 dark:bg-slate-600 transition-all duration-500 dark:text-slate-200 rounded-b-xl flex items-center">
      <div class="flex-auto flex items-center justify-evenly">
        <button @click="backward" type="button" aria-label="Rewind 10 seconds">
          <svg width="24" height="24" fill="none">
            <path
                d="M6.492 16.95c2.861 2.733 7.5 2.733 10.362 0 2.861-2.734 2.861-7.166 0-9.9-2.862-2.733-7.501-2.733-10.362 0A7.096 7.096 0 0 0 5.5 8.226"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M5 5v3.111c0 .491.398.889.889.889H9" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </svg>
        </button>
      </div>
      <button @click="Play" type="button"
              class="bg-white text-slate-900 transition-all duration-500 dark:bg-slate-100 transition-all duration-500 dark:text-slate-700 flex-none -my-2 mx-auto w-20 h-20 rounded-full ring-1 ring-slate-900/5 shadow-md flex items-center justify-center"
              aria-label="Pause">
        <svg v-if="play" width="50" height="52" fill="currentColor" viewBox="0 0 16 16">
          <path
              d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="50" height="52" fill="currentColor" class="bi bi-play"
             viewBox="0 0 16 16">
          <path
              d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z"/>
        </svg>
      </button>
      <div class="flex-auto flex items-center justify-evenly">
        <button @click="forward" type="button" aria-label="Skip 10 seconds" class="">
          <svg width="24" height="24" fill="none">
            <path
                d="M17.509 16.95c-2.862 2.733-7.501 2.733-10.363 0-2.861-2.734-2.861-7.166 0-9.9 2.862-2.733 7.501-2.733 10.363 0 .38.365.711.759.991 1.176"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M19 5v3.111c0 .491-.398.889-.889.889H15" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </button>
        <Dropdown :btnText="playback+'x'">ss
          <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" >
            <li @click="playbackRate(1)">
              <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">1x</a>
            </li>
            <li @click="playbackRate(1.5)">
              <a href="#"
                 class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">1.5x</a>
            </li>
            <li @click="playbackRate(1.75)">
              <a href="#"
                 class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">1.75x</a>
            </li>
            <li @click="playbackRate(2)">
              <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">2x</a>
            </li>
          </ul>
        </Dropdown>
      </div>
    </div>
  </div>
</div>
  


</template>

<style scoped>

</style>