import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'flowbite';
import { emitter } from "@/emitter";
import { MotionPlugin } from '@vueuse/motion'

const app = createApp(App)
app.config.globalProperties.emitter = emitter;

app.use(VueAxios, axios)
app.use(MotionPlugin)

app.mount('#app')
