<script>
import { initFlowbite, initDropdowns } from 'flowbite'

export default {
  name: "Dropdown",
  props: ['btnText'],
  mounted() {
    initFlowbite()
    initDropdowns()
  },
}
</script>

<template>
<button id="dropdownDefaultButton2" data-dropdown-toggle="dropdown2" class="rounded-lg text-xs leading-6 font-semibold px-2 ring-2 ring-inset ring-slate-500 text-slate-500 transition-all duration-500 dark:text-slate-100 transition-all duration-500 dark:ring-0 transition-all duration-500 dark:bg-slate-500" type="button">
  {{ btnText }}
</button>

<!-- Dropdown menu -->
<div id="dropdown2" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
    <slot></slot>
</div>

</template>